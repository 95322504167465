body, html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    display: flex;
    cursor: url('https://storage.googleapis.com/www.minair.me/logo/cursor-sm.png'), auto;
    position:relative;
}

button:focus, button:hover {
  cursor: url('https://storage.googleapis.com/www.minair.me/logo/cursor-sm.png'), auto;
}

p {
  margin: 0;
}

.app {
    flex: 1;
    padding-bottom: 65px;
}

#fiction-modal {
    background-color: rgba(170, 68, 170, 0.8);
}

div[role=presentation].MuiPopover-root {
    background-color: rgba(0,0,0,0);
}

.MuiInputBase-input {
    color: white !important;
}

.MuiInput-underline::before {
    border-bottom-color: white !important;
}
